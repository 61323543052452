<template>
  <DashboardComponent>
    <div slot="slot-pages" class="content-pages">
      <article>
        <div class="container-fluid">
          <!--USAR ROUTER LINK-->
          <div v-show="distribuidoresTotal == 0 ">
            <Spinner/>
          </div>
          <div v-show="distribuidoresTotal != 0 ">
            <div class="row">
              <div class="col">
                <a class="one">
                  <router-link :to="{ name: 'listadistribuidores' }">
                    <div class="card">
                      <div class="card-body importados">
                        <h5 class="card-title">Importados</h5>
                        <p class="card-text">{{ distribuidoresTotal }}</p>
                      </div>
                    </div>
                  </router-link>
                </a>
              </div>
              <!--USAR ROUTER LINK-->
              <div class="col">
                <a class="one"
                  ><router-link :to="{ name: 'ativostotal' }">
                    <div class="card">
                      <div class="card-body ativos">
                        <h5 class="card-title">Ativos</h5>
                        <p class="card-text">
                          {{ distribuidoresAtivosApp }}
                        </p>
                      </div>
                    </div>
                  </router-link></a
                >
              </div>

              <div class="w-100"></div>

              <div class="col">
                <a class="one"
                  ><router-link :to="{ name: 'clientesdistribuidor' }">
                    <div class="card">
                      <div class="card-body clientes">
                        <h5 class="card-title">Cliente Final</h5>
                        <p class="card-text">{{ clientesDistribuidores }}</p>
                      </div>
                    </div>
                  </router-link>
                </a>
              </div>

              <!--USAR ROUTER LINK-->
              <div class="col quatro">
                <a class="one">
                  <router-link :to="{ name: 'mensal' }">
                    <div class="card">
                      <div class="card-body mensal">
                        <h5 class="card-title">Importados Mensal</h5>
                        <p class="card-text">{{ distribuidoresMensal }}</p>
                      </div>
                    </div>
                  </router-link>
                </a>
              </div>
              <div class="row legenda-container">
                <div class="col-2">
                  <div class="cor cor1"></div>
                  <h2>Distribuidores Importados</h2>
                </div>
                <div class="col-2">
                  <div class="cor cor2"></div>
                  <h2>Distribuidores Ativos App</h2>
                </div>
                <div class="col-2">
                  <div class="cor cor3"></div>
                  <h2>Cadastro Cliente Final</h2>
                </div>
                <div class="br"><br /></div>
                <div class="col-2">
                  <div class="cor cor4"></div>
                  <h2>Distribuidores Importados Mensal</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  </DashboardComponent>
</template>

<script>
import DashboardComponent from "../Dashboard/Dashboard.vue";
import { http } from "../../../http/index";
import Spinner from "../Dashboard/components/Spinner.vue";

export default {
  components: {
    DashboardComponent,
    Spinner,
  },

  data() {
    return {
      distribuidoresAtivosApp: 0,
      clientesDistribuidores: 0,
      distribuidoresTotal: 0,
      distribuidoresMensal: 0,
      carregando: false,
      carregado: true,
    };
  },

  computed: {

  },
  created() {
 
    this.spinnerCarrega();
    
 
  },
  methods: {
  
    spinnerCarrega() {
      this.carregando= true;
      this.carregado= false;

      this.totalDistribuidoresAtivos();
      this.totalClientes();
      this.totalDistribuidoresMensal();
      this.totalDistribuidores();

      this.carregando= false;
      this.carregado= true;
    },

    totalClientes() {
      http.get("clientes").then((response) => {
        this.clientesDistribuidores = response.data.length;
        
      });
    },
    totalDistribuidores() {
      http.get("distribuidores").then((response) => {
        this.distribuidoresTotal = response.data.length;
        
      });
    },
    totalDistribuidoresMensal() {
      http.get("distribuidores/mensal").then((response) => {
        this.distribuidoresMensal = response.data.length;
        
      });
    },
    totalDistribuidoresAtivos() {
      http.get("appuser/getAll").then((response) => {
        this.distribuidoresAtivosApp = response.data.length;
        
      });
    },
  },
};
</script>

<style scoped>
.br {
  display: none;
}
article {
  margin-top: 50px;
}
.legenda-container {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.col-2 {
  display: contents;
}
h2 {
  margin-right: 20px;
}
.cor1 {
  margin-left: 50px;
  margin-right: 10px;
  width: 50px;
  height: 50px;
  background: linear-gradient(to right, #01a9ac, #01dbdf);
  float: left;
}
.cor2 {
  margin-right: 10px;
  width: 50px;
  height: 50px;
  background: linear-gradient(to right, #0ac282, #0df3a3);
  float: left;
}
.cor3 {
  margin-right: 10px;
  width: 50px;
  height: 50px;
  background: linear-gradient(to right, #fe5d70, #fe909d);
  float: left;
}
.cor4 {
  margin-right: 10px;
  width: 50px;
  height: 50px;
  background: linear-gradient(to right, #fe9365, #feb798);
  float: left;
}
h2 {
  font-size: 18px;
  text-align: left;
  padding-top: 13px;
}

.importados {
  /*   background: white; */
  border: 2px solid #01dbdf;

  /*   background: linear-gradient(to right,#049497,#01dbdf); */
  /*   background: linear-gradient(to right,#01a9ac,#01dbdf); */
}

.ativos {
  /*   background: white; */
  border: 2px solid #0df3a3;

  /*   background: linear-gradient(to right,#0cab74,#0df3a3); */
  /*   background: linear-gradient(to right,#0ac282,#0df3a3); */
}
.clientes {
  /*  background: white; */
  border: 2px solid #fe909d;

  /*  background: linear-gradient(to right,#d15765,#fe909d); */
  /*  background: linear-gradient(to right,#fe5d70,#fe909d); */
}
.mensal {
  /*  background: white; */
  border: 2px solid #feb798;

  /* background: linear-gradient(to right,#d37f5b,#feb798); */
  /* background: linear-gradient(to right,#fe9365,#feb798); */
}
.content-pages {
  padding: 1px;
}

.card-title {
  font-size: 35px;
}

a:link {
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}
a.one:visited {
  color: white;
}
h1 {
  color: rgb(82, 58, 50);
  text-align: center;
  margin-right: 250px;
}
article {
  height: 100%;
}

.col {
  text-align: center;
}
.card-text {
  font-size: 70px;
  color: black;
}
.card-title {
  padding-top: 1px;
  color: black;
}
.card-body {
  border-radius: 12px;
  width: 500px;
  height: 230px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}
h5,
p {
  color: white !important;
}
.card {
  background: black;
  border: none;
  width: 100%;
  height: 100%;
  margin: 10px 0px;
  color: #888888;
  font-size: 25px;
  border-radius: 12px;
}
@media (max-width: 1600px) {
  .br {
    display: block !important;
  }
}

@media (max-width: 1360px) {
  body {
    background: red;
  }
  /*       .br{
    display:none;
}  */
  .card-body {
    border-radius: 12px;
    width: 400px;
    height: 230px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  }
  .legenda-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }
  .cor1 {
    margin-left: 0;
  }
  .cor {
    margin-right: 0;
  }
  h2 {
    margin-right: 0;
  }
}

@media (max-width: 1180px) {
  .legenda-container {
    display: flex;
    flex-wrap: wrap;
  }
  .cor1 {
    margin-left: 0;
  }
  .cor {
    margin-right: 0;
  }
  h2 {
    margin-right: 0;
  }
  .row {
    padding-left: 5px;
  }

  .card {
    width: 100%;
  }
  .card-body {
    text-align: center;
  }
}
@media (max-width: 550px) {
  .legenda-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }
  .card-body {
    border-radius: 12px;
    width: 300px;
    height: 230px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  }
}
@media (max-width: 450px) {
  .card-body {
    border-radius: 12px;
    width: 250px;
    height: 230px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  }
}
@media (max-width: 350px) {
  .card-body {
    border-radius: 12px;
    width: 220px;
    height: 230px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  }
}
@media (max-width: 310px) {
  .card-body {
    border-radius: 12px;
    width: 200px;
    height: 230px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  }
}
</style>
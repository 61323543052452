import Vue from 'vue'
import VueRouter from 'vue-router'



// importa components
import LoginComponent from '../pages/Login/LoginComponent.vue'
import HomeComponent from '../pages/Home/HomeComponent.vue'
import CadastrarDistribuidoresComponent from '../pages/Distribuidores/DistribuidoresComponent.vue'
import ListaDistribuidoresComponent from '../pages/Distribuidores/ListaDistribuidoresComponent.vue'
import UsuarioComponent from '../pages/Usuarios/UsuarioComponent.vue'
import RelatoriosMenuComponent from '../pages/Relatorios/RelatoriosMenuComponent.vue'
import CadastroUsuarioComponent from '../pages/Usuarios/CadastroUsuarioComponent.vue'
import ListaGeralDistribuidoresComponent from '../pages/Relatorios/ListaGeralDistribuidoresComponent.vue'
import ListaGeralClientesDistribuidoresComponent from '../pages/Relatorios/ListaGeralClientesDistribuidoresComponent.vue'
//import ListaClientesDistribuidorComponent from './pages/Relatorios/ListaClientesDistribuidorComponent.vue'
import ListaGeralUsuariosComponent from '../pages/Relatorios/ListaGeralUsuariosComponent.vue'
import EditDistribuidorComponent from '../pages/Distribuidores/EditDistribuidorComponent.vue'
import EditUsuarioComponent from '../pages/Usuarios/EditUsuarioComponent.vue'
import TotalAtivosComponent from '../pages/Distribuidores/TotalDistribuidoresAtivosComponent.vue'
import ClientesTotalComponenet from '../pages/Distribuidores/ClientesTotalComponent.vue'
import DistribuidoresMensaisComponent from '../pages/Distribuidores/DistribuidoresMensalComponent.vue'
import Details from '../pages/Distribuidores/DetailsComponent.vue'
import PedidosComponent from '../pages/Pedidos/PedidosComponent.vue'
import SugestaoComponent from '../pages/Sugestoes/SugestaoComponent.vue'
import TermoProtecaoDados from '../pages/ProtecaoDados/TermoProtecaoDados.vue'


Vue.use(VueRouter);



function guardMyroute(to, from, next) {
    var isAuthenticated = false;
 
    if (sessionStorage.getItem('token')) {
        isAuthenticated = true;

    } else {
        isAuthenticated = false;
    }

    if (isAuthenticated) {
        next(); // allow to enter route
    } else {
        next("/login"); // go to '/login';
    }

    if (from.path === "/" && !isAuthenticated) {
        next("/login");
    }


}


const routes = [
    { path: "/", name: 'loginUm', component: LoginComponent, beforeEnter: guardMyroute },
    { path: "/login", name: 'login', component: LoginComponent },
    { path: '/Dashboard', name: 'home', component: HomeComponent, beforeEnter: guardMyroute },
    { path: '/distribuidores', name: 'listadistribuidores', component: ListaDistribuidoresComponent, beforeEnter: guardMyroute },
    { path: '/distribuidores/cadastrar', name: 'cadastrodistribuidores', component: CadastrarDistribuidoresComponent, beforeEnter: guardMyroute },
    // { path: '/clientes', name: 'clientes', component: ClienteComponent },
    { path: '/distribuidores/detalhes/:id', name: 'detalhes', component: Details, params: true, beforeEnter: guardMyroute },
    { path: '/distribuidores/editar/:id', name: 'editarDistribuidor', component: EditDistribuidorComponent, params: true, beforeEnter: guardMyroute },
    { path: '/usuarios', name: 'usuarios', component: UsuarioComponent },
    { path: '/usuarios/cadastrar', name: 'cadastrousuarios', component: CadastroUsuarioComponent, beforeEnter: guardMyroute },
    { path: '/relatoriosmenu', name: 'relatoriosmenu', component: RelatoriosMenuComponent, beforeEnter: guardMyroute },
    { path: '/relatorio/distribuidores/geral', name: 'listageraldistribuidores', component: ListaGeralDistribuidoresComponent, beforeEnter: guardMyroute },
    { path: '/relatorio/clientes', name: 'listageralclientesdistribuidores', component: ListaGeralClientesDistribuidoresComponent, beforeEnter: guardMyroute },
    // { path: '/relatorio/clientes/byDistribuidor', name: 'listaclientesdistribuidor', component: ListaClientesDistribuidorComponent, beforeEnter: guardMyroute },
    { path: '/listageralusuarios', name: 'listageralusuarios', component: ListaGeralUsuariosComponent, beforeEnter: guardMyroute },
    { path: '/usuarios/editar/:id', name: 'editusuario', component: EditUsuarioComponent, params: true, beforeEnter: guardMyroute },
    { path: '/distribuidoresAtivos', name: 'ativostotal', component: TotalAtivosComponent, beforeEnter: guardMyroute },
    { path: '/clientesTotal', name: 'clientesdistribuidor', component: ClientesTotalComponenet, beforeEnter: guardMyroute },
    { path: '/distribuidoresMensal', name: 'mensal', component: DistribuidoresMensaisComponent, beforeEnter: guardMyroute },
    { path: '/pedidos', name: 'pedidos', component: PedidosComponent, beforeEnter: guardMyroute },
    { path: '/sugestao', name: 'sugestao', component: SugestaoComponent, beforeEnter: guardMyroute },
    { path: '/termos-protecao-de-dados', name: 'termos', component: TermoProtecaoDados },

]

const router = new VueRouter({
    mode: 'history',
    routes // short for `routes: routes`
})


export default router;
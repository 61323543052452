<template>
    <DashboardComponent>
        <div slot='slot-pages' class="content-pages">
            <MenuRelaComponent></MenuRelaComponent>
        </div>
    </DashboardComponent>
</template>

<script>

import DashboardComponent from '../Dashboard/Dashboard.vue';
import MenuRelaComponent from '../Relatorios/MenuRelaComponent.vue'

export default {
    name: 'RelatoriosMenuComponent',
    components: {
        DashboardComponent,
        MenuRelaComponent,
    }
}
</script>

<style scoped>

    .content-pages{
        padding: 1px;
    }

    article {
    
    height: 100%;
   
  }
  h1{
    text-align: center;
  }
  button{
      width: 200px;
  }
  

</style>
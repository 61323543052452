<template>
  <div class="tudo">
      <br>
    <div class="politica">
        <h1>ACCEPTANCE AGREEMENT FOR THE PROCESSING AND USE OF PERSONAL DATA </h1><br>

        <p>This document aims to register the free, informed and unambiguous expression of intent by which the user agrees to the
            treatment of their personal data for a specific purpose, in conformity with Brazilian Law no. 13.709/2020 - General Law
            for the Protection of Personal Data (LGPD).<br>
            By accepting this term, the USER consents and agrees that the company ECO SISTEMAS E SOLUÇÕES, registered under
            the CNPJ no. 08.951.144/0001-35, headquartered at Rua Taquari, 370 – Zona Nova District – Capão da Canoa/RS, contact
            information: (51) 3625-3486 and (51) 9.9167-2281, electronic address diretoriaecosistemas@gmail.com, hereinafter
            referred to as CONTROLLER, carries out the treatment and use of their personal data, as specified in this agreement. 
        </p><br>

        <h2>1. Regarding the controller</h2>

        <p>The CONTROLLER, a legal entity governed by private law, will decide regarding the treatment of the user’s personal data,
            which will involve operations such as: collection, production, reception, classification, use, access, reproduction,
            transmission, distribution, processing, archiving, storing, deleting, evaluating or controlling data, as well as modifying,
            communicating, transferring, diffusing or extracting the information. 
        </p><br>

        <h2>2. Personal Data </h2>
        <p>The CONTROLLER is authorized to make decisions and to process the following personal data of the HOLDER:</p>
        <ul>
            <li>Full name</li>
            <li>Personal or professional email address;</li>
            <li>Phone number;</li>
            <li>Maxinível Registration, which is the application manager for use on your smartphone;</li>
            <li>Distributor ID and Link;</li>
            <li>Registration of distributor affiliates, containing any and all data that facilitates the processing of information for the
                completion of this agreement.
            </li>
        </ul><br>

        <h2>3. Purposes of Data Processing</h2>

        <p>The processing of personal data listed in this term has the following purposes: </p>

        <ul>
            <li>Enable the CONTROLLER to identify and contact the USER for customer relationship management and quality
                control purposes between the software, usage and user;
            </li>
            <li>Enable the CONTROLLER to give access to the <div class="sublinha">APP CRM Win</div> system for importing and exporting data to the
                 <div class="sublinha">ADMIN CRM Win</div> platform; 
            </li>
            <li>Allow the monitoring of registrations made by the HOLDER and collaborate with whatever is necessary for data
                confidentiality, market research and for the data that are indispensable for the CONTROLLER to comply with legal or
                regulatory obligations; 
            </li>
        </ul>

        <div class="negrito">First Section.</div><p>The user is aware that the aforementioned purposes will not be treated in a way that is incompatible with what is
            described. New changes will only be carried out upon specific and express agreement of the USER, thus delimiting a new
            objective of data processing.
        </p><br>

        <h2>4. Data Sharing</h2>

        <p>The CONTROLLER is authorized to share the HOLDER's personal data with other data processing agents, in this case with
            MAXÍNÍVEL, to meet the purposes listed in this agreement, subject to the principles and guarantees established by the
            Brazilian General Data Protection Law (LGPD).<br>
            The database registered by the HOLDER will not be shared with third parties with the purpose of marketing information
            for commercial use or for other purposes beyond the treatment and security of information provided for user registrations
            when they join the application.
        </p><br>

        <h2>5. Data Security and Prevention</h2>

        <p>The Controller is responsible for maintaining security, technical and administrative measures capable of protecting
            personal data from unauthorized access and from accidental or unlawful situations of destruction, loss, alteration,
            communication or any form of inappropriate or unlawful treatment.<br>
            According to article 48 of the Brazilian Law No. 13.709/2020, the Controller will notify the HOLDER and the Autoridade
            Nacional de Proteção de Dados (ANPD) of the occurrence of a security incident that may cause relevant risk or damage to
            the Holder.<br>
            Attending to the principle of Prevention, the Controller will adopt data protection measures before, during and after the
            treatment, being its duty to look after the data before third parties who have access to the information registered by the
            USER with the application in question
        </p><br>

        <h2>6. On Transparency, Open Access and Communication</h2>

        <p>The CONTROLLER must provide the user with objective, clear and succinct language regarding whatever is happening with
            their data to ensure easy access to the treatment of available information, as well as in this agreement to accept the
            conditions of use and privacy policies. The USER may contact the company at any time, thus avoiding offending data
            protection regulations.
        </p><br>

        <h2>7. Rights of the Holder</h2>

        <p>The HOLDER has the right to obtain from the CONTROLLER, regarding the processed data, at any time and upon request: I
            - confirmation of the existence of treatment; II - access to data; III - correction of incomplete, inaccurate or outdated
            data; IV - anonymization, blocking or erasure of unnecessary, excessive or data that is processed in disagreement with
            the provisions of Law No. 13.709/2020; V - data portability to another service or product provider, upon express request,
            in accordance with the regulations of the national authority, observing commercial and industrial secrets; VI - erasure of
            personal data processed with the consent of the holder, except in the cases provided for in article 16 of Law No.
            13.709/2020; VII - information on public and private entities with which the controller shared data; VIII - information on
            the possibility of not providing consent and on the consequences of denial; IX - withdraw of consent, pursuant to Section
            5 of article 8 of Law No. 13.709/2020.
        </p><br>

        <h2>8. End of Data Processing</h2>

        <p>The CONTROLLER may keep and process the HOLDER's personal data during the entire period that the user is registered
            in the APP CRM Win system and ADMIN CRM Win platform relevant to the scope of the purposes listed in this
            agreement, after treatment, becoming inactive for the CONTROLLER.<br>
            The database operated by the USER throughout the use of the application will be retained with the CONTROLLER only
            as a source of record or even for reporting to the Agência Nacional de Proteção de Dados. These data may be kept for
            an indefinite period, without the possibility of association with the individual who lives up to the description, and in
            cases of association, the HOLDER may request the erasure of personal data information.<br>
            Since the purpose of the application is only to ensure the security, maintenance and organization of the administered
            data by the HOLDER, the HOLDER may request its base with the intention that it deems correct for maintenance,
            exempting the CONTROLLER from any negligence, imprudence or malpractice reasons that may occur.<br>
            For the request to be carried out, it is enough to request the termination of the contract through the CONTROLLER's
            contact means, e-mail <a href="">diretoriaecosistemas@gmail.com</a>, providing the CONTROLLER with a period of up to 30 (thirty)
            days to make the information available on the electronic address registered by the USER. 
        </p><br>

        <h2>9. Right to Withdraw Consent</h2>

        <p>This consent may be withdrawn by the user, at any time, upon an express and clear request, via email
            <a href="">diretoriaecosistemas@gmail.com</a> or correspondence to the CONTROLLER, providing the CONTROLLER a period of 30
            (thirty) days to receive the request, adopt the appropriate measures and prove the exclusion at the same address as the request.</p>




    </div>
    <br>
  </div>
</template>

<script>
export default {};
</script>


<style scoped>

.politica{
    border: 2px solid gray;
    padding: 8px;
    width: 80%;
    margin-left: auto;
    margin-right: auto; 
    text-align: justify;
}

h1{
    font-size: 20px;
    text-align: center;
    color: black;
}

h2{
    font-size: 18px;
    color: black;
}
.sublinha{
    text-decoration: underline;
    display: inline;
}
.negrito{
    font-weight: bold
   
}


</style>

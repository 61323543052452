<template>
  <div class="main">
    <div class="sidebar">
      <SidebarComponent />
    </div>
    <div class="content-right">
      <div class="navbar">
        <NavbarComponent />
      </div>
      <div class="pages">
        <slot name="slot-pages"> </slot>
      </div>
    </div>
  </div>
</template>

<script>
import SidebarComponent from "./components/SidebarComponent.vue";
import NavbarComponent from "./components/NavbarComponent.vue";


export default {
  name: "DashboardComponent",
  components: {
    SidebarComponent,
    NavbarComponent,
  },

};
</script>

<style scoped>
.main {
    display: flex;
    width: 100%;
    height: 100vh;
}

.sidebar {
    width: 300px;
    height: 100%;
    background-color: rgb(31, 21, 19);
    margin: 0;
    padding: 0;
    box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
    transition: 0, 5s;
    overflow: hidden;
}

.content-right {
    width: calc(100% - 300px);
    height: 100vh;
    background-color: #1f1f1f;
    ;
}

.navbar {
    width: 100%;
    height: 50px;
    padding: 0;
}

.pages {
    width: 100%;
    height: auto;
    color: white;
    background-color: #1f1f1f;
    ;
}

@media(max-width: 800px) {
    .sidebar {
        width: 20%;
        height: 100%;
        background-color: rgb(31, 21, 19);
        margin: 0;
        padding: 0;
        box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
        transition: 0, 5s;
        overflow: hidden;
    }
    .navbar {
        width: 100%;
        height: 50px;
        padding: 0;
        /*        background-color: whitesmoke; */
    }
    .content-right {
        width: calc(100% - 20%);
        height: 100vh;
        background-color: white;
    }
}
</style>
<template>
  <dashboard-component>
    <div slot="slot-pages" class="content-pages">
      <article>
        <div class="container-fluid">
          <br />
          <h1>Lista de Pedidos</h1>
          <br />

          <div class="row">
            <!--BUTTONS DE IMPORT E NOVO CADASTRO-->
            <div class="col"></div>
            <div class="col"></div>
            <div class="col">
              <button class="buttonRefresh" @click="getPedidos()">
                Novo Pedidos
              </button>
            </div>
          </div>
          <div class="row"  v-if="spinner2">
            <div class="col">
              <div class="input-group">
                <select class="form-select" v-model="nPages">
                  <option disabled value="">Escolha uma pagina</option>
                  <option v-for="(item, index) in nPedidos" :key="index">
                    {{ item }}
                  </option>
                </select>
              </div>
            </div>
            
            <div class="col">
              <button
                type="button"
                class="buttonSearch"
                @click.prevent="getTotalPedidos(nPages)"
              >
                Filtrar
              </button>
            </div>
            
            <div class="col"></div>
            <div class="col"></div>
            <div class="col"></div>
          </div>
          <div v-else>
              <spinner-component></spinner-component>
            </div>
          <br /><br />
          <div class="pedidos" v-if="spinner">
            <div class="row">
              <div class="col-sm-12">
                <div class="card" v-for="pedido in pedidos" :key="pedido.id">
                  <div class="card-body">
                    <h5 class="card-title">Pedido ID: {{ pedido.id }}</h5>
                    <div class="row">
                      <div class="col-4">
                        <div class="row">
                          <div class="col">
                            <div class="status">
                              Data:
                              <p>{{ pedido.data_adicionado }}</p>
                            </div>
                          </div>
                          <div class="w-100"></div>
                          <div class="col">
                            <div class="status">
                              Nome:
                              <p>
                                {{ pedido.cliente_nome }}
                                {{ pedido.cliente_sobrenome }}
                              </p>
                            </div>
                          </div>
                          <div class="w-100"></div>
                          <div class="col">
                            <div class="status">
                              Telefone:
                              <p>{{ pedido.cliente_telefone }}</p>
                            </div>
                          </div>

                          <div class="w-100"></div>
                          <div class="col">
                            <div class="status">
                              Forma Pagamento:
                              <p>{{ pedido.pagamento_metodo_descricao }}</p>
                            </div>
                          </div>
                          <div class="w-100"></div>
                          <div class="col">
                            <div class="status">
                              Status:
                              <p>{{ pedido.status_descricao }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-8">
                        <div class="table-wrapper-scroll-y my-custom-scrollbar">
                          <table
                            class="table table-bordered table-striped mb-0"
                          >
                            <thead>
                              <tr>
                                <th scope="col">Produto</th>
                                <th scope="col">Tamanhos</th>
                                <th scope="col">Qtd</th>
                                <th scope="col">Valor Unit</th>
                                <th scope="col">Valor Total</th>
                              </tr>
                            </thead>
                            <tbody
                              v-for="item in pedido.itens"
                              :key="item.item_id"
                            >
                              <tr
                                v-for="tamanho in item.produto_opcoes"
                                :key="tamanho.produto_opcao_valor_id"
                              >
                                <td>{{ item.produto_descricao }}</td>
                                <td>{{ tamanho.valor }}</td>
                                <td>{{ item.quantidade }}</td>
                                <td>{{ item.valor_unitario }}</td>
                                <td>{{ item.valor_total }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="w-100"></div>
                      <div class="col-4">
                        <div class="row">
                          <div class="col">
                            <div class="status">
                              Valor Total:
                              <p>{{ pedido.valor_total }}</p>
                            </div>
                          </div>
                          <div class="col">
                            <!-- <button class="buttonImport">Import</button> -->
                          </div>
                        </div>
                      </div>
                      <div class="col-8">
                        <div v-if="pedidos.status_pedido === '0'">
                          <div class="row">
                            <div class="col">
                              <button class="buttonStatus">Status</button>
                            </div>
                            <div class="col">
                              <button class="buttonAprovar">
                                Aprovar Pedido
                              </button>
                            </div>
                            <div class="col">
                              <button class="buttonNegar">Negar Pedido</button>
                            </div>
                          </div>
                        </div>
                        <div v-else-if="pedidos.status_pedido === '1'">
                          <div class="row">
                            <div class="col">
                              <!-- <button class="buttonStatus" disabled>Status</button> -->
                            </div>
                            <div class="col">
                              <!-- <button class="buttonAprovar" disabled>Aprovar Pedido</button> -->
                            </div>
                            <div class="col">
                              <!-- <button class="buttonNegar">Negar Pedido</button> -->
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <div class="row">
                            <div class="col">
                              <!-- <button class="buttonStatus" disabled>Status</button> -->
                            </div>
                            <div class="col">
                              <!-- <button class="buttonAprovar" >Aprovar Pedido</button> -->
                            </div>
                            <div class="col">
                              <!-- <button class="buttonNegar" disabled>Negar Pedido</button> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
          <div class="spinn" v-else>
            <div class="row">
              <div class="col"></div>
              <div class="col">
                <spinner-component></spinner-component>
              </div>
              <div class="col"></div>
            </div>
          </div>
        </div>
      </article>
    </div>
  </dashboard-component>
</template>

<script>
import DashboardComponent from "../Dashboard/Dashboard.vue";
import SpinnerComponent from "../Dashboard/components/Spinner.vue";
import axios from "axios";
export default {
  name: "PedidosComponent",
  components: {
    DashboardComponent,
    SpinnerComponent,
  },

  data() {
    return {
      tokenMaxin: null,
      pedidos: [],
      totalPages: {},
      peTotal: {},
      nPages: null,
      spinner: true,
      spinner2: false,
      nPedidos: [],

      validation: {
        client_id: "ALLINCRMW_8ebae0074",
        client_secret: "6c441e81fa1b80e80fb7128690ae1844f7e82487",
        grant_type: "client_credentials",
      },
    };
  },

  methods: {

     tokenMaxinivel() {
      axios
        .post("https://allinbrasil.com.br/api/v1/auth/token", this.validation)
        .then((response) => {
          if (response.status == 200) {
            localStorage.setItem("tokenMax", response.data.access_token);
          }
        })
        .catch((erro) => console.log(erro));
    },
    getPedidos() {
      this.spinner2 = false;
      this.tokenMaxinivel();
      this.pedidos = [];

      // this.totalPages = '';
      // this.peTotal = '';
      // this.nPages = '';
      //Pega o valor de pages total//

      this.tokenMaxin = localStorage.getItem("tokenMax");
      axios
        .get(`https://allinbrasil.com.br/api/v1/pedidos?page=1&cancelado=0`, {
          headers: {
            Authorization: `Bearer ${this.tokenMaxin}`,
          },
        })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.totalPages = response.data;
            // this.nPages = this.totalPages._infos.total_pages - 1;
            this.nPages = this.totalPages._infos.total_pages;

            //console.log(this.nPages);

            //this.getTotalPedidos();
          }
        })
        .finally(() => {
          for (let i = 1; i <= this.nPages; i++) {
            this.nPedidos.push(i);
          }
          this.nPedidos.reverse();

          this.spinner2 = true;
        })
        .catch((error) => console.log(error));
    },

    //Pega as ultimas paginas de pedidos (so pedidos aprovados)//

    async getTotalPedidos(nPage) {
      this.spinner = false;
      this.tokenMaxinivel();
      this.pedidos = [];

      //console.log(nPage);
      //let number = 0;
      // for (let i = this.nPages; i >= 0; i--) {
        // if (number != 3) {
         // number = number + 1;
          this.tokenMaxin = localStorage.getItem("tokenMax");
          await axios
            .get(
              `https://allinbrasil.com.br/api/v1/pedidos?&loja_id=0&page=${nPage}&cancelado=0`,
              {
                headers: {
                  Authorization: `Bearer ${this.tokenMaxin}`,
                },
              }
            )
            .then((response) => {
              if (response.status === 200) {
                this.peTotal = "";
                this.peTotal = response.data;
                this.peTotal.pedidos.reverse();

                this.peTotal.pedidos.forEach((res) => {
                  res.status_pedido = 0;

                  this.pedidos.push(res);
                });
              }
            })
            .catch((error) => console.log(error));
      //  }
    //  }
      //console.log(this.pedidos);
      //console.log(this.pedidos.id);

      //  for (let i = 1; i < this.pedidos.length; i++) {
      //    for (let j = 0; j < i; j++) {
      //       if (this.pedidos[i].id > this.pedidos[j].id) {
      //       var aux = this.pedidos[i].id;

      //       this.pedidos[i].id = this.pedidos[j].id;

      //       this.pedidos[j].id = aux;
      //       }

      //    }

      //  }

      //console.log(this.pedidos);

      this.reverseData();
      this.tradaTamanhoVazio();
      this.tradaValorDinheiro();

      this.spinner = !this.spinner;
    },

    //muda a data de
    reverseData() {
      this.pedidos.forEach((pedi) => {
        const var1 = pedi.data_adicionado.split(" ");
        const var2 = var1[0].split("-");
        var2.reverse();
        pedi.data_adicionado = `${var2[0]}/${var2[1]}/${var2[2]}`;
      });
      //console.log(this.pedidos);
    },

    tradaTamanhoVazio() {
      this.pedidos.forEach((pedi) => {
        pedi.itens.forEach((item) => {
          if (item.produto_opcoes.length == 0) {
            let tamanho = {
              valor: "n/d",
            };

            item.produto_opcoes.push(tamanho);
          }
        });
      });
    },
    tradaValorDinheiro() {
      this.pedidos.forEach((pedi) => {
        var v = pedi.valor_total.replace(/\D/g, "");
        v = (v / 10000).toFixed(2) + "";
        v = v.replace(".", ",");
        v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
        v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
        pedi.valor_total = v;

        pedi.itens.forEach((item) => {
          var u = item.valor_unitario.replace(/\D/g, "");
          u = (u / 10000).toFixed(2) + "";
          u = u.replace(".", ",");
          u = u.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
          u = u.replace(/(\d)(\d{3}),/g, "$1.$2,");
          item.valor_unitario = u;

          var t = item.valor_total.replace(/\D/g, "");
          t = (t / 10000).toFixed(2) + "";
          t = t.replace(".", ",");
          t = t.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
          t = t.replace(/(\d)(\d{3}),/g, "$1.$2,");
          item.valor_total = t;
        });
      });
    },
  },
  created() {
    //this.getPedidos();
  },
  mounted() {
    this.getPedidos();
  },
};
</script>

<style scoped>
h1 {
  color: white;
  text-align: center;
}
.my-custom-scrollbar {
  position: relative;
  height: 300px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}

td {
  color: rgb(255, 255, 255);
  text-align: center;
}
th {
  text-align: center;
  color: #ffce40;
}
.buttonSearch {
  font-weight: bold;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  background-color: rgb(31, 21, 19);
  color: rgb(255 193 7);
  border: none;
  padding: 5px;
  position: center;
}

.buttonRefresh {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  background-color: #ffce40;
  border: none;
  color: white;
  font-weight: bold;
}

.buttonImport {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  background-color: rgb(39 164 227);
  border: none;
  color: white;
  font-weight: bold;
}
.spinn col {
  text-align: center;
}
.card-title {
  color: #eaab00;
  font-size: 20px;
}
.card {
  background-color: #1f1f1f;
  padding: 7px;
}
.card-body {
  background-color: black;
  height: 100%;
  border: 4px solid #eaab00;
  margin: 0 auto;
  padding: 2px;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAOqrAP///yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=="),
    url("data:image/gif;base64,R0lGODlhAQABAPAAAOqrAP///yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=="),
    url("data:image/gif;base64,R0lGODlhAQABAPAAAP///////yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==");
  background-repeat: no-repeat;
  background-size: 0 2px, 0 100%, 0% 2px;
  background-position: top center, top center, bottom center;
  -webkit-animation: drawBorderFromCenter 4s;
}

@-webkit-keyframes drawBorderFromCenter {
  0% {
    background-size: 0 2px, 0 0, 100% 100%;
  }
  20% {
    background-size: 100% 2px, 100% 0, 100% 100%;
  }
  66% {
    background-size: 100% 2px, 100% 98%, 100% 100%;
  }
  99% {
    background-size: 100% 2px, 100% 98%, 0 2px;
  }
}

.card p {
  color: rgb(255, 255, 255);
}
.status {
  color: orange;
  font-size: 17px;
  padding-left: 5px;
}

.tabela {
  width: 100%;
  height: 100%;
}
.dados {
  color: aqua;
}
.card-text {
  font-weight: normal;
  font-size: 16px;
}
</style>
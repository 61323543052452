<template>
  <div class="sidebar">
    <div class="top">
      <div class="info">
        <img src="@/assets/images/logo_all_in.png" />
      </div>
    </div>
    <div class="container-fluid">
      <aside>
        <a
          ><router-link :to="{ name: 'home' }"
            ><div class="icon"><i class="fas fa-home"></i></div>
            <div class="title">Dashboard</div></router-link
          ></a
        ><br />
        <a
          ><router-link :to="{ name: 'listadistribuidores' }"
            ><div class="icon"><i class="fas fa-users"></i></div>
            <div class="title">Distribuidores</div></router-link
          ></a
        ><br />
        <a
          ><router-link :to="{ name: 'pedidos' }"
            ><div class="icon"><i class="fas fa-file-invoice-dollar"></i></div>
            <div class="title">Pedidos</div></router-link
          ></a
        ><br />
        <a
          ><router-link :to="{ name: 'usuarios' }"
            ><div class="icon"><i class="fas fa-user"></i></div>
            <div class="title">Usuário</div></router-link
          ></a
        ><br />
        <a
          ><router-link :to="{ name: 'listageraldistribuidores' }"
            ><div class="icon"><i class="fas fa-chart-pie"></i></div>
            <div class="title">Relatório</div></router-link
          ></a
        ><br />
        <a
          ><router-link :to="{ name: 'sugestao' }"
            ><div class="icon"><i class="fas fa-tree"></i></div>
            <div class="title">Ajuda</div></router-link
          ></a
        ><br />
      </aside>
    </div>
  </div>
</template>


<script>
export default {
  name: "SidebarComponent",
};
</script>

<style scoped>
.sidebar {
  background-color: black !important;
  text-align: center;
  font-size: 16px;
  position: fixed;
  height: 100%;
}

.top {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
  padding-bottom: 0;
}

.info {
  /* padding-bottom: 20px; */
}

img {
  width: 210px;
  height: 200px;
  filter: brightness(180%);
}

.icon {
  margin-right: 10px;
  width: 20px !important;
  height: 20px !important;
}

aside a {
  font-family: "Mulish", Helvetica, Arial;
  display: inline-flex;
  color: #ffce40;
  padding: 5px 10px;
  text-decoration: none;
  font-size: 24px;
  width: 100%;
  text-align: center;
}

aside a.active {
  background-color: rgb(63, 64, 73);
  color: white;
}

aside a:hover:not(.active) {
  background-color: rgb(63, 64, 73);
  color: white;
}


@media (max-width: 800px) {
  .sidebar {
    text-align: center;
    font-size: 6px;
    position: fixed;
  }

  .top {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 5px;
  }
  .icon {
    display: inline-flex;
    padding: 2px;
  }

  aside a {
    font-family: "Mulish", Helvetica, Arial;
    display: block;
    padding: 2px 0px;
    padding-top: 20px;
    text-decoration: none;
    font-size: 12px;
  }

  img {
    visibility: hidden;
  }
}
@media (max-width: 490px) {
  aside a {
    font-family: "Mulish", Helvetica, Arial;
    display: block;
    padding: 2px 0px;
    padding-top: 20px;
    text-decoration: none;
    font-size: 12px;
  }
  .title {
    visibility: hidden;
  }
}
</style>
<template>
  <div>
    <DashboardComponent>
      <div slot="slot-pages" class="content-pages">
        <article>
          <h1>Fale Conosco</h1>
          <div class="texto">
            <p>
              Bem vindo à ECO Sistemas e Soluções, estamos sempre em busca de
              melhorar a experiência do cliente usuário, e primando pelo seu
              conforto criamos este canal onde você pode deixar sugestões de
              melhoria ou fazer uma solicitação de contato para nosso suporte.
              Descreva abaixo o motivo do seu contato e deixe seu telefone ou
              WhatsApp que o mais breve possível nosso suporte irá retornar para
              lhe auxiliar. Obrigado(a) pelo seu contato, e se surgir alguma
              dúvida adicional, basta nos procurar novamente. Será um prazer
              atendê-lo(a)!
            </p>

            <textarea
              class="form-control"
              placeholder="Escreva aqui!"
              v-model="textoResposta"
            ></textarea>

            <div class="row">
              <div class="col">
                <button class="buttonSave" @click="cadastraTexto()">
                  Enviar
                </button>
              </div>
              <div class="col"></div>
              <div class="col"></div>
            </div>
          </div>
        </article>
      </div>
    </DashboardComponent>
  </div>
</template>



<script>
import DashboardComponent from "../Dashboard/Dashboard.vue";
//import axios from "axios";
import { ecoAdmin } from "../../../http/index";
//import SpinnerComponent from "../Dashboard/components/Spinner.vue";

//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";

export default {
  name: "SugestaoComponent",
  components: {
    DashboardComponent,
    //SpinnerComponent
  },

  data() {
    return {
      textoResposta: "",
      nomeCliente: "all-in Brasil",
    };
  },

  methods: {
    cadastraTexto() {
      const mensagem = {
        empresa: this.nomeCliente,
        texto: this.textoResposta,
      };

      ecoAdmin
        .post("/mensagem", mensagem)
        .then(() => {
          //console.log(response);
        })
        .catch((error) => {
          console.log(error);
          this.$fire({
            title: "Opss...",
            text: "Algo deu errado, por favor tente novamente",
            type: "error",
            timer: 5000,
          });
        })
        .finally(() => {
          this.$fire({
            title: "Sucesso",
            text: "Sua Mensagem foi enviado com sucesso!",
            type: "success",
            timer: 5000,
          });
          this.textoResposta = "";
        });
    },
  },
};
</script>
<style scoped>
.content-pages {
  padding: 1px;
}
article {
  background-color: #1f1f1f;
  height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
h1 {
  text-align: center;
  margin-top: 15px;
}
.texto {
  width: 80%;
  height: 100%;
  margin-left: 10%;
  margin-top: 20px;
  font-size: 22px;
  text-align: justify;
  justify-content: center;
}
textarea {
  height: 350px;
  font-size: 22px;
}
.buttonSave {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  font-weight: bold;
  background-color: #ffce40;
  color: white;
  border: none;
}
.buttonCancel {
  font-weight: bold;
  color: white;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  background-color: rgb(67, 192, 255);
  border: none;
}
.row {
  margin-top: 10px;
}
</style>
import axios from 'axios';



export const http = axios.create({
    baseURL: 'https://api.all-in.crmwin.eco.br/public/api/',
    headers:{
        'Accept' : 'application/json',
        'Content' : 'application/json'
    }
})
export const ecoAdmin = axios.create({
    baseURL: 'https://api.ecoteste.crmwin.eco.br/public/api/',
    headers:{
        'Accept' : 'application/json',
        'Content' : 'application/json'
    }
})

 http.interceptors.request.use(function (config) {
     
     const token = sessionStorage.getItem('token')

      if(token){
          config.headers.Authorization = `Bearer ${token}`;
      }
    

     return config;
 }, function(erro){

     return Promise.reject(erro)
 })


export const httpMaxinivel = axios.create({
    baseURL:'/',
    headers:{
        'Accept' : 'application/json',
        'Content' : 'application/json',
    }

})


<template>
  <DashboardComponent>
    <div slot="slot-pages" class="content-pages">
      <div v-show="checkSpinner">
        <div class="row">
          <div class="col"></div>
          <div class="col"><SpinnerComponent/></div>
          <div class="col"></div>
        </div>
        
      </div>
      <div v-show="checkSpinnerFalse">
<article>
        <div class="container-fluid">
          <br />
          <h1>{{ titulo }}</h1>
          <br />

          <div class="row">
            <!--BUTTONS DE IMPORT E NOVO CADASTRO-->
            <div class="col"></div>
            <div class="col"></div>
            <div class="col">
              <button
                type="button"
                class="buttonImprimir"
                @click="generatePDF()"
              >
                Imprimir
              </button>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col">
              <div class="form-group input-group">
                <input
                  name="consulta"
                  id="filterbox"
                  placeholder="Pesquisar"
                  type="search"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col">
              <div class="input-group">
                <select class="form-select" v-model="mes">
                  <option disabled value="">Escolha uma data</option>
                  <option v-for="item in mesAtivos" :key="item.id">
                    {{ item }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col">
              <button
                type="button"
                class="buttonSearch"
                @click.prevent="mesFiltrados()"
              >
                Filtrar
              </button>
            </div>
            <div class="col"></div>
          </div>

          <br />
          <div class="table-responsive">
            <table class="table" id="tabelaUm">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Tipo</th>
                  <th scope="col">Nome</th>
                  <th scope="col">Usuario Maxinivel</th>
                  <th scope="col">Cidade</th>
                  <th scope="col">UF</th>
                  <th scope="col">Telefone</th>
                  <th scope="col">Data Criação</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </article>
      </div>
      
    </div>
    <link
      rel="stylesheet"
      type="text/css"
      href="//cdn.datatables.net/1.10.13/css/jquery.dataTables.css"
    />
    <link
      rel="stylesheet"
      href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"
    />
  </DashboardComponent>
</template>

<script>
import DashboardComponent from "../Dashboard/Dashboard.vue";
//import {mapState, mapActions} from 'vuex';
import { http } from "../../../http/index";
const { jsPDF } = require("jspdf");
import "jspdf-autotable";
import SpinnerComponent from "../Dashboard/components/Spinner.vue";
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
export default {
  name: "DistribuidoresMensaisComponent",
  components: {
    DashboardComponent,
    SpinnerComponent
  },
  data() {
    return {
      checkSpinner: true,
      checkSpinnerFalse: false,
      titulo: "Lista Distribuidores do mes atual",
      mes: "",
      filterDistri: [],
      distriMensal: [],
      distriTotal: [],
      distriTotalTratado: [],
      entradaDistriMes: [],
      mesAtivos: [],
      mesFiltrada: [],
    };
  },

  computed: {
    //...mapState("distribuidores", ["distribuidoresMensal"]),
  },
  methods: {
    // ...mapActions("distribuidores", ["getDistribuidoresMensal"]),

    mesFiltrados() {
      console.log(this.mes);
      this.mesFiltrada = [];
      if (this.mes == null) {
        this.$fire({
          title: "Ops..",
          text: "Nenhuma data foi selecionada!!",
          type: "error",
          timer: 5000,
        });
       
      }else{
         this.distriTotalTratado.forEach((item) => {
          if (this.mes === item.datacria) {
            this.mesFiltrada.push(item);
          }
        });

        (this.filterDistri = []), (this.filterDistri = this.mesFiltrada);
        this.teble();
      }

      //console.log(this.filterDistri);
    },

    filterDistribuidores() {
      this.distriMensal.forEach((distribuidor) => {
        while (!distribuidor.id_maxinivel) {
          distribuidor.id_maxinivel = "Não informado";
        }
        if (distribuidor.id_maxinivel.includes("excluido")) {
          distribuidor.id_maxinivel = "Excluido";
        }
        if (!distribuidor.celular) {
          distribuidor.celular = "Não informado";
        }
        if (!distribuidor.nome) {
          distribuidor.celular = "Não informado";
        }
        if (!distribuidor.tipo) {
          distribuidor.tipo = "Indeterminavel";
        }
        if (!distribuidor.cidade) {
          distribuidor.cidade = {
            descricao: "Não informado",
            uf: "Não informado",
          };
        }
        let corte = distribuidor.created_at.split("T");

        let divisao = corte[0].split("-");
        distribuidor.created_at = `${divisao[2]}-${divisao[1]}-${divisao[0]} `;
        this.filterDistri.push(distribuidor);
      });
      this.teble();
    },
    filterDistribuidoresTotal() {
      this.distriTotal.forEach((distribuidor) => {
        while (!distribuidor.id_maxinivel) {
          distribuidor.id_maxinivel = "Não informado";
        }
        if (distribuidor.id_maxinivel.includes("excluido")) {
          distribuidor.id_maxinivel = "Excluido";
        }
        if (!distribuidor.celular) {
          distribuidor.celular = "Não informado";
        }
        if (!distribuidor.nome) {
          distribuidor.celular = "Não informado";
        }
        if (!distribuidor.tipo) {
          distribuidor.tipo = "Indeterminavel";
        }
        if (!distribuidor.cidade) {
          distribuidor.cidade = {
            descricao: "Não informado",
            uf: "Não informado",
          };
        }
        let corte = distribuidor.created_at.split("T");

        let divisao = corte[0].split("-");
        distribuidor.created_at = `${divisao[2]}-${divisao[1]}-${divisao[0]} `;
        let mes = `${divisao[1]}-${divisao[0]}`;
        distribuidor.datacria = mes;

        //console.log(distribuidor.created_at);
        this.entradaDistriMes.push(mes);
        this.distriTotalTratado.push(distribuidor);
      });
      //console.log(this.entradaDistriMes);
      //console.log(this.entradaDistriMes);
      let unico = this.entradaDistriMes.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });
      this.mesAtivos = unico;
      //onsole.log(this.filterDistri);
      //console.log(this.distriTotalTratado);
    },

    teble() {

      this.checkSpinner = true;
      this.checkSpinnerFalse = false;

      $.noConflict();
      var table = $("#tabelaUm").DataTable({
        stateSave: false,
        bDestroy: true,
        scrollCollapse: true,
        data: this.filterDistri,
        columns: [
          { data: "tipo" },
          { data: "nome" },
          { data: "id_maxinivel" },
          { data: "cidade.descricao" },
          { data: "cidade.uf" },
          { data: "celular" },
          { data: "created_at" },
        ],
        processing: true,
        language: {
          url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Portuguese-Brasil.json",
        },
        //Tira a funçao de ordenar de uma coluna
        //tira o input padrao de busca do dataTable
        dom: "lrtip",
      });
      //Coloca a funçao de filtro (busca) em um input personalizado
      $("#filterbox").on("keyup", function () {
        table.search(this.value).draw();
      });

      this.checkSpinner = false;
      this.checkSpinnerFalse = true;
    },
    getUser() {
      http.get("distribuidores/mensal").then((response) => {
        this.distriMensal = response.data;
       
        this.filterDistribuidores();
      });
    },
    getDistribudorTotal() {
      http.get("distribuidores").then((response) => {
        this.distriTotal = response.data;

        this.filterDistribuidoresTotal();
      });
    },
    generatePDF() {
      const doc = new jsPDF("p", "pt", "a4");
      doc.text(this.titulo, 15, 15);
      doc.autoTable({ html: "#tabelaUm" });
      doc.save("Lista_Distribuidores_Mensal.pdf");
    },
  },

  created() {},

  mounted() {
    this.getUser();
    this.getDistribudorTotal();
  },
};
</script>


<style scoped>
.content-pages {
  padding: 1px;
}
article {
  background-color: #1f1f1f;
  height: 100%;
}
e {
  visibility: hidden;
}
h1 {
  text-align: center;
}
td {
  background-color: #1f1f1f;
  color: white;
}
input[type="text"] {
  border: 1px solid black;
  border-radius: 4px;
}
thead {
  font-size: 18px;
  text-align: center;
  background-color: rgb(156, 153, 153);
}
tbody {
  font-size: 17px;
  text-align: center;
}
.btn btn-success {
  font-weight: bold;
  background-color: #ffce40;
  color: white;
}

.buttonImprimir {
  font-weight: bold;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  background-color: rgb(67, 192, 255);
  color: white;
  border: none;
  padding: 5px;
}
.buttonSearch {
  font-weight: bold;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  background-color: rgb(31, 21, 19);
  color: rgb(255 193 7);
  border: none;
  padding: 5px;
  position: center;
}

@media (max-width: 1100px) {
  h1 {
    text-align: center;
    font-size: 25px;
  }
  .buttonImprimir {
    height: 40px;
    width: 100%;
    border-radius: 4px;
    border: none;
    padding: 5px;
  }
}
</style>
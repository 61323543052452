<template>
  <DashboardComponent>
    <div slot="slot-pages" class="content-pages">
      <MenuRelaComponent></MenuRelaComponent>
      <br /><br />
      <div v-show="checkSpinner">
        <div class="row">
          <div class="col"></div>
          <div class="col"><SpinnerComponent /></div>
          <div class="col"></div>
        </div>
      </div>
      <div v-show="checkSpinnerFalse">
        <div class="container-fluid">
          <div class="row">
            <div class="col"></div>
            <div class="col"></div>
            <div class="col">
              <button
                type="button"
                class="buttonImprimir"
                @click="generatePDF()"
              >
                Imprimir
              </button>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col">
              <div class="form-group input-group">
                <input
                  name="consulta"
                  id="filterbox"
                  placeholder="Pesquisar"
                  type="search"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <br />
          <h1>{{ titulo }}</h1>
          <div class="table-responsive">
            <table class="table" id="geralTres">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">#ID</th>
                  <th scope="col">Nome</th>
                  <th scope="col">Usuario</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="usuario in usuarios" :key="usuario.id_admin">
                  <td>{{ usuario.id_admin }}</td>
                  <td>{{ usuario.nome }}</td>
                  <td>{{ usuario.usuario }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </DashboardComponent>
</template>


<script>
//import { mapState, mapActions } from "vuex";
import DashboardComponent from "../Dashboard/Dashboard.vue";
import MenuRelaComponent from "./MenuRelaComponent.vue";
import SpinnerComponent from "../Dashboard/components/Spinner.vue";
const { jsPDF } = require("jspdf");
import "jspdf-autotable";
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import { http } from "../../../http/index";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

export default {
  name: "ListaGeralUsuariosComponent",
  components: {
    DashboardComponent,
    MenuRelaComponent,
    SpinnerComponent,
  },
  data() {
    return {
      checkSpinner: true,
      checkSpinnerFalse: false,
      titulo: "Lista de Usuarios",
      usuarios: [],
    };
  },

  created() {
    this.getUsuarios();
  },

  mounted() {},

  methods: {
    // ...mapActions("usuarios", ["getUsuarios"]),

    montaTabela() {
      $(document).ready(function () {
        var table = $("#geralTres").DataTable({
          language: {
            url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Portuguese-Brasil.json",
          },

          //Tira a funçao de ordenar de uma coluna
          //tira o input padrao de busca do dataTable
          dom: "lrtip",
        });
        //Coloca a funçao de filtro (busca) em um input personalizado
        $("#filterbox").on("keyup", function () {
          table.search(this.value).draw();
        });
      });
      this.checkSpinner = false;
      this.checkSpinnerFalse = true;
    },

    getUsuarios() {
      this.checkSpinner = true;
      this.checkSpinnerFalse = false;

      http
        .get("users")
        .then((response) => {
          this.usuarios = response.data;
          console.log(this.users);
        })
        .catch((erro) => {
          console.log(erro);
        })
        .finally(() => {
          this.montaTabela();
        });
    },

    generatePDF() {
      const doc = new jsPDF("p", "pt", "a4");
      doc.text(this.titulo, 15, 15);
      doc.autoTable({ html: "#geralTres" });
      doc.save("table.pdf");
    },
  },
};
</script>

<style scoped>
.content-pages {
  padding: 1px;
}

article {
  height: 100%;
}
h1 {
  text-align: center;
}
thead {
  font-size: 18px;
  text-align: center;
  background-color: rgb(156, 153, 153);
}
tbody {
  font-size: 17px;
  text-align: center;
}
.buttonImprimir {
  font-weight: bold;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  background-color: rgb(39 164 227);
  color: white;
  border: none;
}

select {
  height: 35px;
}
</style>
<template>
        <div slot='slot-pages' class="content-pages">
                <article>
                    <div class="container-fluid">
                        <h1>Relatorios</h1>
                            <br>
                        <div class="row">
                            <div class="col">
                                <router-link :to="{name: 'listageraldistribuidores'}"><button type="button" class="buttonList">Lista geral de Distribuidores</button></router-link>
                            </div>
                            <div class="col">
                                <router-link :to="{name: 'listageralclientesdistribuidores'}"><button type="button" class="buttonList">Lista geral de clientes dos Distribuidores</button></router-link>
                            </div>
                           
                            <div class="col">
                                 <router-link :to="{name: 'listageralusuarios'}"><button type="button" class="buttonList">Lista Geral de Usuarios<br></button></router-link>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
</template>

<script>



export default {
    name: 'MenuRelaComponent',
    components: {
       
    }
}
</script>

<style scoped>

    .content-pages{
        padding: 1px;
    }

    .col{
        padding: 5px;
    }

    article {
    height: 100%;
  }
  h1{
    text-align: center;
  }
  .buttonList{
    font-weight: bold;
    width:100%;
    height:50px;
    border-radius: 4px;
   background-color: rgb(71 64 62);
/*     color: rgb(239, 148, 0); */
    color: #ffce40;
    border: none;
}


</style>
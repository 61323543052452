<template>
  <DashboardComponent>
    <div slot="slot-pages" class="content-pages">
      <div class="container-fluid">
        <MenuRelaComponent></MenuRelaComponent>
        <br /><br />
        <div v-show="checkSpinner">
          <div class="row">
            <div class="col"></div>
            <div class="col"><SpinnerComponent/></div>
            <div class="col"></div>
          </div>
            
        </div>
        <div v-show="checkSpinnerFalse">
  <div class="row">
          <div class="col"></div>
          <div class="col"></div>
          <div class="col">
            <button type="button" class="buttonImprimir" @click="generatePDF()">
              Imprimir
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="input-group">
              <select class="custom-select mr-sm-2" v-model="uf">
                <option disabled value="">Escolha um Estado</option>
                <option value="all">Todos</option>
                <option value="AC">Acre</option>
                <option value="AL">Alagoas</option>
                <option value="AP">Amapá</option>
                <option value="AM">Amazonas</option>
                <option value="BA">Bahia</option>
                <option value="CE">Ceará</option>
                <option value="DF">Distrito Federal</option>
                <option value="ES">Espirito Santo</option>
                <option value="GO">Goiás</option>
                <option value="MA">Maranhão</option>
                <option value="MS">Mato Grosso do Sul</option>
                <option value="MT">Mato Grosso</option>
                <option value="MG">Minas Gerais</option>
                <option value="PA">Pará</option>
                <option value="PB">Paraíba</option>
                <option value="PR">Paraná</option>
                <option value="PE">Pernambuco</option>
                <option value="PI">Piauí</option>
                <option value="RJ">Rio de Janeiro</option>
                <option value="RN">Rio Grande do Norte</option>
                <option value="RS">Rio Grande do Sul</option>
                <option value="RO">Rondônia</option>
                <option value="RR">Roraima</option>
                <option value="SC">Santa Catarina</option>
                <option value="SP">São Paulo</option>
                <option value="SE">Sergipe</option>
                <option value="TO">Tocantins</option>
              </select>
              <div class="input-group-append">
                <button
                  class="buttonSearch"
                  type="button"
                  v-on:click="getUser(uf)"
                  style="width: 100px"
                >
                  Pesquisar
                </button>
              </div>
            </div>
          </div>
          <div class="col"></div>
          <div class="col"></div>
        </div>
        <br />
        <div>
          <h1>{{ titulo }}</h1>
          <div class="table-responsive">
            <table class="table" id="geralUm">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Tipo</th>
                  <th scope="col">Nome</th>
                  <th scope="col">Usuario Maxinivel</th>
                  <th scope="col">Cidade</th>
                  <th scope="col">UF</th>
                  <th scope="col">Telefone</th>
                </tr>
              </thead>
              <tbody>
                  
               
              </tbody>
            </table>
          </div>
        </div>
        </div>

      
      </div>
    </div>
  </DashboardComponent>
</template>

<script>
import DashboardComponent from "../Dashboard/Dashboard.vue";
import MenuRelaComponent from "./MenuRelaComponent.vue";
import SpinnerComponent from "../Dashboard/components/Spinner.vue";
import { mapState, mapActions } from "vuex";
import { http } from "../../../http/index";
const { jsPDF } = require("jspdf");
import "jspdf-autotable";
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

export default {
  name: "ListaGeralComponent",
  components: {
    DashboardComponent,
    MenuRelaComponent,
    SpinnerComponent
  },
  data() {
    return {
      checkSpinner: true,
      checkSpinnerFalse: false,
      uf: "",
      titulo: 'Lista geral de Distribuidores por Estado',
      filterDistri: [],
      distriUf: []
    };
  },
  computed: {
    ...mapState("distribuidores", ["distribuidoresUf"]),
  },
  created() {
       
   
  },
  mounted() {
        this.getUser("all");
        //this.filterDistribuidores();
        
  },
  methods: {
    ...mapActions("distribuidores", ["getByUf"]),
    filterDistribuidores() {
        this.filterDistri = [],
      this.distriUf.forEach((distribuidor) => {
        while (!distribuidor.id_maxinivel) {
          distribuidor.id_maxinivel = "Não informado";
        }
        if (distribuidor.id_maxinivel.includes("excluido")) {
          distribuidor.id_maxinivel = "Excluido";
        }
        if (!distribuidor.celular) {
          distribuidor.celular = "Não informado";
        }
        if (!distribuidor.nome) {
          distribuidor.celular = "Não informado";
        }
        if (!distribuidor.tipo) {
          distribuidor.tipo = "Indeterminavel";
        }
        if (!distribuidor.cidade) {
          distribuidor.cidade = {
            descricao: "Não informado",
            uf: "Não informado",
          };
        }
        
        this.filterDistri.push(distribuidor);
      });
      this.table()
    },

    table() {
      this.checkSpinner = true;
      this.checkSpinnerFalse = false;
        
       $("#geralUm").DataTable({
        stateSave: false,
        "bDestroy": true,
        "scrollCollapse": true,
        
        data: this.filterDistri,
        columns: [
        { data: 'tipo' },
        { data: 'nome' },
        { data: 'id_maxinivel' },
        { data: 'cidade.descricao'},
        { data: 'cidade.uf'},
        { data: 'celular'}
        ],
          processing: true,
          language: {
            url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Portuguese-Brasil.json",
          },
          //Tira a funçao de ordenar de uma coluna
          columnDefs: [{ orderable: false, targets: 5 }],
          order: [[1, "asc"]],
          //tira o input padrao de busca do dataTable
          dom: "lrtip",
        });

      this.checkSpinner = false;
      this.checkSpinnerFalse = true;
     
    },

    getUser(uf){
        http.get(`distribuidores/${uf}`)
        .then(response =>{
            this.distriUf = response.data;
            this.filterDistribuidores();
        })
    },

    generatePDF() {
      const doc = new jsPDF("p", "pt", "a4");
      doc.text(this.titulo, 15, 15);
      doc.autoTable({ html: "#geralUm" });
      doc.save("Lista_Distribuidores.pdf");
    },
  },
};
</script>


<style scoped>
.content-pages {
  padding: 1px;
}
article {
  height: 100%;
}
h1 {
  text-align: center;
}

td {
  background-color: #1f1f1f;
  color: white;
}
thead {
  font-size: 18px;
  text-align: center;
  background-color: rgb(156, 153, 153);
}
tbody {
  font-size: 17px;
  text-align: center;
  background-color: #1f1f1f;
}
.row {
  padding: 5px;
}
.buttonDatails {
  font-weight: bold;
  height: 35px;
  width: 100%;
  border-radius: 4px;
  background-color: rgb(192, 22, 177);
  color: white;
  border: none;
}
.buttonImprimir {
  font-weight: bold;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  background-color: rgb(39 164 227);
  color: white;
  border: none;
  padding: 5px;
}
.buttonSearch {
  font-weight: bold;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  background-color: rgb(31, 21, 19);
  color: rgb(255 193 7);
  border: none;
  padding: 5px;
  position: center;
}
select {
  height: 40px;
  width: 300px;
  border-radius: 4px;
}
</style>
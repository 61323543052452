<template>
  <div class="navbar">
    <h4 class="logo"></h4>
    <h6>
      <a href="" @click.prevent="logout" class="logout">Sair <i class="fas fa-sign-out-alt"></i></a>
    </h6>
  </div>
</template>

<script>
export default {
  name: "NavbarComponent",
  methods: {
    logout() {
      sessionStorage.removeItem("token");
      this.$router.push({ name: "login" });
    },
    // sugestao(){
    //     this.$router.push({name: 'sugestao'})
    // }
  },
};
</script>

<style scoped>
.navbar {
  background-color: #ffce40;
}
.logo {
  color: rgb(255, 255, 255);
  padding: 5px;
}

.logout {
  color: black;
  text-decoration: none;
}

.logout:hover {
  color: black;
}
img {
  width: 180px;
  height: 60px;
}
h6 {
  padding-right: 15px;
  font-size: 20px;
}
</style>